import request from '@/utils/request';
import config from '@/api/config';
export function getList(data) {
  data = {
    ...data
  };
  let url = config.messageListHttp;
  if (data.msgClass == 4) {
    url = config.msgCommentListHttp;
    delete data.msgClass;
  }
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
//指定已读
export function setMsgStatus(data) {
  data = {
    ...data
  };
  let url = config.messageStatusHttp;
  if (data.msgClass == 4) {
    url = config.msgCommentStatusHttp;
  }
  delete data.msgClass;
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
//全部已读
export function setMsgAllReaded(data) {
  data = {
    ...data
  };
  let url = config.messageAllReadedHttp;
  if (data.msgTypeClass == 4) {
    url = config.msgCommentAllReadedHttp;
    delete data.msgTypeClass;
  }
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
// 未读消息数量
export function getUnreadCount() {
  let url = config.messageUnreadCountHttp + '?v=' + new Date().getTime(); // 参数v防止报重复提交
  return request({
    url: url,
    method: 'post'
  });
}