const i18n = {
  'en-US': {
    'menu.exception': 'Exception page',
    'menu.exception.403': '403',
    'exception.result.403.description': 'Access to this resource on the server is denied.',
    'exception.result.403.back': 'Back',
    'dict.403.no.access': 'No access',
    'dict.403.no.access.info': 'No access to Digital Risk Protection Platform. Please contact your customer manager.',
    'dict.403.more.info': 'For more information, please contact us.'
  },
  'zh-CN': {
    'menu.exception': '异常页',
    'menu.exception.403': '403',
    'exception.result.403.description': '对不起，您没有访问该资源的权限',
    'exception.result.403.back': '返回',
    'dict.403.no.access': '权限未开通',
    'dict.403.no.access.info': 'DRP 数字风险防护平台权限尚未开通，请联系您的客户经理，开通服务内容',
    'dict.403.more.info': '如需了解更多服务详情， 请联系我们'
  }
};
export default i18n;