import globalI18n from '@/locale';
const i18n = {
  'en-US': {
    ...globalI18n['en-US'],
    'menu.visualization': 'Data Visualization',
    'menu.visualization.analysis': 'Analysis',
    'dataAnalysis.yesterday': 'Yesterday',
    'dataAnalysis.title.publicOpinion': 'Public Opinion Analysis',
    'dataAnalysis.publicOpinion.visitor': 'Total visitors',
    'dataAnalysis.publicOpinion.content': 'Total content publishing',
    'dataAnalysis.publicOpinion.comment': 'Total comments',
    'dataAnalysis.publicOpinion.share': 'Total share',
    'dataAnalysis.title.publishingRate': 'Content publishing rate',
    'dataAnalysis.title.publishingTiming': 'Content period analysis',
    'dataAnalysis.title.authorsList': 'Top authors list',
    'dataAnalysis.authorTable.rank': 'Rank    ',
    'dataAnalysis.authorTable.author': 'Author',
    'dataAnalysis.authorTable.content': 'Interval volume',
    'dataAnalysis.authorTable.click': 'Click volume',
    'control.tab.item.risk': 'Risks',
    'control.tab.item.ticket': 'Tickets',
    'control.tab.item.all': 'Select All',
    'control.confirm.pass.ticket': 'Please confirm pass current ticket',
    'control.reject.reason': 'Reject Reason',
    'control.edit.note': 'Edit Note',
    'control.monitoring.nodes': 'Nodes',
    'control.monitoring.access.status': 'Access Status',
    'control.monitoring.status.code': 'Status Code',
    'control.monitoring.time': 'Time',
    'control.comment.deleted': 'This comment has been deleted',
    'control.edit.ticket': 'Edit Ticket',
    'control.no.data.report': 'The data does not exist, do you want to generate a ticket report?',
    'control.confirm.cancel.ticket': 'Cancel the ticket submitted or not?',
    'control.select.same.brand.type.risk': 'For batch selects, risks should be under the same brand and in the same scenario.',
    'control.before.edit.cancel': 'Before change, the ticket will be turned into review cancellation status, continue or not?',
    'control.no.ticket.can.create': 'No ticket，you can',
    'control.no.ticket': 'No ticket',
    'dict.dark.group': 'Group'
  },
  'zh-CN': {
    ...globalI18n['zh-CN'],
    'menu.visualization': '数据可视化',
    'menu.visualization.analysis': '分析页',
    'dataAnalysis.yesterday': '较昨日',
    'dataAnalysis.title.publicOpinion': '舆情分析',
    'dataAnalysis.publicOpinion.visitor': '访问总人数',
    'dataAnalysis.publicOpinion.content': '内容发布量',
    'dataAnalysis.publicOpinion.comment': '评论总量',
    'dataAnalysis.publicOpinion.share': '分享总量',
    'dataAnalysis.title.publishingRate': '内容发布比例',
    'dataAnalysis.title.publishingTiming': '内容时段分析',
    'dataAnalysis.title.authorsList': '热门作者榜单',
    'dataAnalysis.authorTable.rank': '排名',
    'dataAnalysis.authorTable.author': '作者',
    'dataAnalysis.authorTable.content': '内容量',
    'dataAnalysis.authorTable.click': '点击量',
    'control.tab.item.risk': '风险',
    'control.tab.item.ticket': '工单',
    'control.tab.item.all': '全部',
    'control.confirm.pass.ticket': '是否通过当前工单',
    'control.reject.reason': '驳回原因',
    'control.edit.note': '编辑备注',
    'control.monitoring.nodes': '节点',
    'control.monitoring.access.status': '访问状态',
    'control.monitoring.status.code': '状态码',
    'control.monitoring.time': '记录时间',
    'control.comment.deleted': '该条评论已删除',
    'control.edit.ticket': '编辑工单',
    'control.no.data.report': '数据不存在，是否重新生成确认单',
    'control.confirm.cancel.ticket': '是否确定撤销已提交的工单',
    'control.select.same.brand.type.risk': '请选择同品牌，同类型风险进行批量处置',
    'control.before.edit.cancel': '执行修改操作前，工单将转为撤销审核状态，是否继续？',
    'control.no.ticket.can.create': '暂无工单，你可以',
    'control.no.ticket': '暂无工单',
    'dict.dark.group': '群组'
  }
};
export default i18n;