import request from '@/utils/request';
import config from '@/api/config';
//获取阿里云相关参数
export function getPublicKey() {
  return request({
    url: config.getPublicKeyHttp,
    method: 'post',
    isLoading: true
  });
}
//获取阿里云相关参数
export function logout() {
  return request({
    url: config.logoutHttp,
    method: 'get',
    isLoading: true
  });
}

//获取阿里云相关参数
export function login(data) {
  return request({
    url: config.loginHttp,
    method: 'post',
    data: data,
    noError: true,
    isLoading: true,
    noQuery: true
  });
}