import langStorage from '@/utils/langStorage';
import locale from '@/locale';
import { isValidIPv4Format, isValidIPv6Format } from '@/utils/util';
let lang = localStorage.getItem('arco-lang');
lang = lang ? lang.split('-')[0] == 'en' ? 'en' : '' : '';
const tl = langStorage(locale);
export const statusCloseReason = [{
  name: '客户撤销处置',
  enname: 'Customer revocation disposition'
}, {
  name: '客户无法提供必须资料',
  enname: 'The client cannot provide the necessary information'
}, {
  name: '阿里云回复需执法机关裁决',
  enname: 'Ali cloud reply to the law enforcement authority ruling'
}, {
  name: '客户服务周期结束',
  enname: 'Customer service cycle ends'
}, {
  name: '开单错误',
  enname: 'Error opening sheet'
}, {
  name: '开单重复',
  enname: 'Order to repeat'
}, {
  name: '平台无法处置',
  enname: 'The platform cannot be disposed of'
}, {
  name: '其他',
  enname: 'Other'
}];
export const statusPendingReason = [{
  name: '需上传侵权项',
  enname: 'Infringement items need to be uploaded'
}, {
  name: '其他',
  enname: 'Other'
}];
export const dataType = [{
  name: '全部',
  enname: 'All',
  id: ''
}, {
  name: '风险',
  enname: 'Risk',
  id: 0
}, {
  name: '工单',
  enname: 'Ticket',
  id: 1
}];
export const appType = [{
  name: 'IOS',
  enname: 'IOS',
  id: 'IOS'
}, {
  name: '安卓',
  enname: 'Android',
  id: 'Android'
}];
export const assetDict = [{
  name: '域名',
  enname: 'Domain',
  type: 'DOMAIN_NAME',
  confirmKey: 'domainName',
  detailDict: [{
    name: '域名',
    enname: 'Domain',
    key: 'domainName',
    type: 'textarea',
    minRow: 5,
    placeholder: '请输入域名，多个换行分割',
    enplaceholder: 'Enter domain name, multiple line breaks for segmentation',
    required: [{
      required: true,
      message: tl['dict.enter.domain'],
      dataMessage: '请输入域名'
    }, {
      validator: (value, callback) => {
        let reg = /^(?:(?:https?|ftp):\/\/)?([^/?#@]+)(?::(\d+))?([^?#@]+)?(\?[^#@]+)?(#.*)?$/i;
        let znReg = /^[\u4e00-\u9fa5A-Za-z0-9\-\.]+\.[a-z]{2,}$/;
        let urls = value.split('\n');
        urls.map(item => {
          if (!item) {
            return true;
          }
          if (!reg.test(item) && !znReg.test(item)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(lang == 'en' ? 'The domain name format is incorrect' : '域名格式不正确');
            return false;
          }
        });
      }
    }]
  }, {
    name: '域名',
    enname: 'Domain',
    key: 'domainName',
    type: 'input',
    enplaceholder: tl['dict.enter.domain'],
    placeholder: '请输入域名',
    required: [{
      required: true,
      message: tl['dict.enter.domain'],
      dataMessage: '请输入域名'
    }, {
      validator: (value, callback) => {
        let reg = /^(?:(?:https?|ftp):\/\/)?([^/?#@]+)(?::(\d+))?([^?#@]+)?(\?[^#@]+)?(#.*)?$/i;
        let znReg = /^[\u4e00-\u9fa5A-Za-z0-9\-\.]+\.[a-z]{2,}$/;
        if (!reg.test(value) && !znReg.test(value)) {
          // console.log('url格式不正确，请输入http(s)://');
          callback(lang == 'en' ? 'The domain name format is incorrect' : '域名格式不正确');
          return false;
        }
      }
    }]
  }, {
    name: '备注',
    enname: tl['dict.remark'],
    key: 'remark',
    type: 'textarea',
    minRow: 2,
    placeholder: '请输入备注信息',
    enplaceholder: tl['dict.placeholder.remark']
  }]
}, {
  name: 'IP',
  enname: 'IP',
  type: 'IP',
  confirmKey: 'ip',
  detailDict: [{
    name: 'IP',
    enname: 'IP',
    key: 'ip',
    type: 'textarea',
    minRow: 5,
    placeholder: '请输入IP，多个换行分割',
    enplaceholder: 'enter IP address, multiple line breaks for segmentation',
    required: [{
      required: true,
      message: tl['dict.enter.IP'],
      dataMessage: '请输入IP'
    }, {
      validator: (value, callback) => {
        let ips = value.split('\n');
        ips.map(item => {
          if (!item) {
            return true;
          }
          let ipv4Flag = isValidIPv4Format(item);
          let ipv6Flag = isValidIPv6Format(item);
          if (!(ipv4Flag || ipv6Flag)) {
            // callback('IP格式错误');
            callback(tl['dict.IP.format']);
            return false;
          }
        });
      }
    }]
  }, {
    name: 'IP',
    enname: 'IP',
    key: 'ip',
    type: 'input',
    placeholder: '请输入IP',
    required: [{
      required: true,
      message: tl['dict.enter.IP'],
      dataMessage: '请输入IP'
    }, {
      validator: (value, callback) => {
        let ipv4Flag = isValidIPv4Format(value);
        let ipv6Flag = isValidIPv6Format(value);
        if (!(ipv4Flag || ipv6Flag)) {
          // callback('IP格式错误');
          callback(tl['dict.IP.format']);
          return false;
        }
      }
    }]
  }, {
    name: '备注',
    enname: tl['dict.remark'],
    key: 'remark',
    type: 'textarea',
    minRow: 2,
    placeholder: '请输入备注信息',
    enplaceholder: tl['dict.placeholder.remark']
  }]
}, {
  name: 'APP',
  enname: 'APP',
  type: 'APP',
  detailDict: [{
    name: 'logo上传',
    enname: 'Logo',
    key: 'icon',
    type: 'upload',
    required: [{
      required: true,
      message: tl['dict.upload.logo'],
      dataMessage: '请上传logo'
    }]
  }, {
    name: '官方APP名称',
    enname: 'Name',
    key: 'name',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: tl['dict.enter.name'],
    required: [{
      required: true,
      message: tl['dict.enter.name'],
      dataMessage: '请输入app名称 '
    }]
  }, {
    name: 'APP环境',
    enname: 'App Type',
    key: 'type',
    type: 'select',
    placeholder: '请选择APP环境',
    enplaceholder: 'Select App Type'
  }, {
    name: 'APP官网下载链接',
    enname: 'Download URL',
    key: 'appUrl',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter Download URL'
  }, {
    name: 'APP程序HASH值',
    enname: 'Hash',
    key: 'sha256',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter Hash'
  }, {
    name: '备注',
    enname: tl['dict.remark'],
    key: 'remark',
    type: 'textarea',
    minRow: 2,
    placeholder: '请输入备注信息',
    enplaceholder: tl['dict.placeholder.remark']
  }]
}, {
  name: '小程序',
  enname: 'Mini Program',
  type: 'MINI_PROGRAM',
  detailDict: [{
    name: '官方小程序商标logo',
    enname: 'Logo',
    key: 'icon',
    type: 'upload',
    required: [{
      required: true,
      message: tl['dict.upload.logo'],
      dataMessage: '请上传logo'
    }]
  }, {
    name: '官方小程序名称',
    enname: 'Name',
    key: 'name',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter Name',
    required: [{
      required: true,
      message: tl['dict.enter.name'],
      dataMessage: '请输入小程序名称'
    }]
  }, {
    name: 'AppID',
    enname: 'AppID',
    key: 'appId',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter AppId',
    required: [{
      required: true,
      message: tl['Enter AppId'],
      dataMessage: '请输入AppID'
    }]
  }, {
    name: '备注',
    enname: tl['dict.remark'],
    key: 'remark',
    type: 'textarea',
    minRow: 2,
    placeholder: '请输入备注信息',
    enplaceholder: tl['dict.placeholder.remark']
  }]
}, {
  name: '社交媒体',
  enname: 'Social Media',
  type: 'SOCIAL_ACCOUNT',
  detailDict: [{
    name: '平台',
    enname: tl['dict.platform'],
    key: 'platformId',
    type: 'select',
    placeholder: '请选择平台',
    enplaceholder: 'Select Platform',
    required: [{
      required: true,
      dataMessage: '请选择平台',
      message: tl['dict.select.platform']
    }]
  }, {
    name: '账号ID',
    enname: tl['dict.account.id'],
    key: 'accountId',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter ' + tl['dict.account.id']
  }, {
    name: '账号名称',
    enname: 'Account',
    key: 'accountName',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: tl['dict.input.account']
  }, {
    name: '账号链接',
    enname: 'Account Url',
    key: 'accountUrl',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: tl['dict.input.account.url']
  }, {
    name: '备注',
    enname: tl['dict.remark'],
    key: 'remark',
    type: 'textarea',
    minRow: 2,
    placeholder: '请输入备注信息',
    enplaceholder: tl['dict.placeholder.remark']
  }]
}, {
  name: '注册商标',
  enname: 'Registered Trademark',
  type: 'TRADEMARK',
  detailDict: [{
    name: '注册商标Logo',
    key: 'icon',
    type: 'upload',
    required: [{
      required: true,
      message: tl['dict.upload.logo'],
      dataMessage: '请上传logo'
    }]
  }, {
    name: '注册商标名称',
    key: 'name',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: tl['dict.enter.name'],
    required: [{
      required: true,
      message: tl['dict.enter.name'],
      dataMessage: '请输入注册商标名称'
    }]
  }, {
    name: '注册号',
    enname: 'Number',
    key: 'registrationNumber',
    type: 'input',
    placeholder: tl['dict.enter.registration.number'],
    required: [{
      required: true,
      message: tl['dict.enter.registration.number'],
      dataMessage: '请输入注册号'
    }]
  }, {
    name: '备注',
    enname: tl['dict.remark'],
    key: 'remark',
    type: 'textarea',
    minRow: 2,
    placeholder: '请输入备注信息',
    enplaceholder: tl['dict.placeholder.remark']
  }]
}, {
  name: '软件著作',
  enname: 'Software Copyright',
  type: 'SOFTWARE_COPYRIGHT',
  detailDict: [{
    name: '名称',
    enname: 'Name',
    key: 'name',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter Name',
    required: [{
      required: true,
      message: tl['dict.enter.name'],
      dataMessage: '请输入名称'
    }]
  }, {
    name: '简称',
    enname: 'Abbreviation',
    key: 'abbr',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter Abbreviation',
    required: [{
      required: true,
      message: tl['dict.enter.abbreviation'],
      dataMessage: '请输入简称'
    }]
  }, {
    name: '版本号',
    enname: 'Version Number',
    key: 'softwareVersion',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter Version Number',
    required: [{
      required: true,
      message: tl['dict.enter.version.number'],
      dataMessage: '请输入版本号'
    }]
  }, {
    name: '登记号',
    enname: 'Registration Number',
    key: 'registrationNumber',
    type: 'input',
    placeholder: '请输入',
    enplaceholder: 'Enter Registration Number',
    required: [{
      required: true,
      message: tl['dict.enter.copyright.registration.number'],
      dataMessage: '请输入登记号'
    }]
  }, {
    name: '备注',
    enname: tl['dict.remark'],
    key: 'remark',
    type: 'textarea',
    minRow: 2,
    placeholder: '请输入备注信息',
    enplaceholder: tl['dict.placeholder.remark']
  }]
}];
export const recordStatusDict = [{
  name: '提交工单',
  enname: 'Submit ticket',
  value: 1
}, {
  name: '处置风险',
  enname: 'Takedown risk',
  value: 2
}, {
  name: '撤销工单',
  enname: 'Withdrawl ticket',
  value: 3
}, {
  name: '审核工单',
  enname: 'Verify ticket',
  value: 4
}, {
  name: '驳回工单',
  enname: 'Reject ticket',
  value: 5
}, {
  name: '工单完成',
  enname: 'Finish ticket',
  value: 6
}, {
  name: '重开工单',
  enname: 'Re-open Ticket',
  value: 7
}, {
  name: '工单暂停',
  enname: 'Ticket pending',
  value: 9
}, {
  name: '关闭工单',
  enname: 'Close ticket',
  value: 10
}, {
  name: '更新工单',
  enname: 'Update ticket',
  value: 11
}, {
  name: '客户内部审核',
  enname: 'Customer Internal Audit',
  value: 12
}, {
  name: '客户内部驳回工单',
  enname: 'Reject ticket by customer',
  value: 13
}, {
  name: '客户内部创建工单',
  enname: 'Create ticket by customer',
  value: 14
}, {
  name: '已联系域名解析商',
  enname: 'Contacted DNS Provider',
  value: 50
}, {
  name: '已联系域名注册商',
  enname: 'Contacted Domain Registra',
  value: 51
}, {
  name: '已联系网络服务商',
  enname: 'Contacted ISP',
  value: 52
}, {
  name: '已联系站主',
  enname: 'Contacted Website Owner',
  value: 53
}, {
  name: 'IP变更',
  enname: '',
  value: 58
}];
export const monitorStatusDict = [{
  name: '响应正常',
  enname: 'OK',
  value: 1
}, {
  name: '响应不正常',
  enname: 'Failed',
  value: 2
}];
export const sortTypeDict = [{
  name: '发现时间',
  enname: tl['dict.discovery.time'],
  value: 'discovery_time'
}, {
  name: '上传时间',
  enname: tl['dict.upload.time'],
  value: 'upload_time'
}];
export const brandExpiredDict = [{
  name: '全部',
  enname: 'All',
  value: ''
}, {
  name: '有效期内',
  enname: 'Valid',
  value: 1
}, {
  name: '已过期',
  enname: 'Expired',
  value: 2
}];
export const digitalRiskReportStatusDict = [{
  name: '待生成',
  enname: 'Preparing',
  value: 1
}, {
  name: '生成中',
  enname: 'Generating',
  value: 2
}, {
  name: '已生成',
  enname: 'Finish',
  value: 3
}, {
  name: '生成失败',
  enname: 'Fail',
  value: -1
}];
export const digitalUploadDict = [{
  name: '请上传生成报告所需的数据文件',
  enname: '请上传生成报告所需的数据文件',
  placeholder: '仅支持 Excel格式',
  enplaceholder: '仅支持 Excel格式',
  key: 'uploadFileInfoVoList',
  type: 'upload',
  uploadType: 'file',
  required: [{
    required: true,
    message: tl['dict.upload.file']
  }]
}];
export const digitalReportDict = [{
  name: '客户&品牌',
  enname: tl['company.brand'],
  key: 'brands',
  type: 'checkbox',
  required: [{
    required: true,
    dataMessage: '请选择品牌',
    message: tl['select.brand']
  }]
}, {
  name: '风险截止日期',
  enname: tl['dict.select.risk.end.date'],
  placeholder: '请选择风险截止日期',
  enplaceholder: '请选择风险截止日期',
  key: 'reportEndTime',
  type: 'date',
  required: [{
    required: true,
    dataMessage: '请选择风险截止日期',
    message: '请选择风险截止日期'
  }]
}];
export const reportTemplateDict = [{
  name: '天际友盟',
  value: 1
}, {
  name: '合作伙伴 ',
  value: 2
}];
export const customItemsDict = [{
  name: '显示所有服务项',
  value: 'ALL_SERVICE_OFFERINGS'
}, {
  name: '不统计已忽略风险 ',
  value: 'WITHOUT_IGNORE_RISKS'
}, {
  name: '不统计已关闭工单 ',
  value: 'WITHOUT_CLOSED_TICKETS'
}, {
  name: '不统计已驳回工单 ',
  value: 'WITHOUT_REJECTED_TICKETS'
}, {
  name: '不统计风险等级 ',
  value: 'WITHOUT_RISK_LEVEL'
}, {
  name: '包含风险/工单截图 ',
  value: 'INCLUDE_SCREENSHOT'
}, {
  name: '统计所属行业所有品牌风险 ',
  value: 'ALL_BRANDS_RISKS_IN_INDUSTRY'
}, {
  name: '不包含风险态势内容 ',
  value: 'WITHOUT_RISK_SITUATION'
}];
export const statisticalCycleDict = [{
  name: '默认模板',
  enname: '默认模板',
  info: '（统计时间为每个月的1号00:00:00到最后一天的23:59:59）',
  value: 1
}, {
  name: '周报',
  enname: '周报',
  info: '（统计时间为每周一的00:00:00到周日23:59:59）',
  value: 2
}, {
  name: '季报',
  enname: '季报',
  info: '（统计时间为1月1日/4月1日/7月1日/10月1日00:00:00到3月31日/6月30日/9月30日/12月31日23:59:59）',
  value: 3
}, {
  name: '自定义',
  enname: '自定义',
  info: '',
  value: 4
}];
export const customReportStatus = [{
  name: '待生成',
  enname: '待生成',
  value: 0
}, {
  name: '生成中',
  enname: '生成中',
  value: 1
}, {
  name: '已生成',
  enname: '已生成',
  value: 2
}, {
  name: '生成失败',
  enname: '生成失败',
  value: 3
}];
export const customReportTaskStatus = [{
  name: '关闭',
  enname: '关闭',
  value: 0
}, {
  name: '开启',
  enname: '开启',
  value: 1
}];
export const reportFormatDict = [{
  name: 'Word',
  enname: 'Word',
  value: 2
}, {
  name: 'Pdf',
  enname: 'Pdf',
  value: 1
}];
export const reportLanguageDict = [{
  name: '简体中文',
  enname: 'Chinese',
  value: 'zh-cn'
}, {
  name: '英文',
  enname: 'English',
  value: 'en'
}];
export const weekListDict = [{
  name: '周一',
  enname: '周一',
  value: 1
}, {
  name: '周二',
  enname: '周二',
  value: 2
}, {
  name: '周三',
  enname: '周三',
  value: 3
}, {
  name: '周四',
  enname: '周四',
  value: 4
}, {
  name: '周五',
  enname: '周五',
  value: 5
}, {
  name: '周六',
  enname: '周六',
  value: 6
}, {
  name: '周日',
  enname: '周日',
  value: 7
}];
export const dateListDict = () => {
  let list = [];
  let i = 1;
  while (i <= 31) {
    list.push({
      name: `${i}日`,
      enname: i,
      value: i
    });
    i++;
  }
  return list;
};

// 报告、报表字段
export const reportFormDict = {
  0: {
    name: '自动报告',
    list: [{
      name: '报告名称',
      enname: tl['dict.report.name'],
      enplaceholder: tl['dict.placeholder.report.name'],
      placeholder: '请输入报告标题',
      key: 'name',
      type: 'input',
      required: [{
        required: true
      }]
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请输入描述',
      enplaceholder: tl['dict.placeholder.description'],
      key: 'description',
      type: 'input'
    }, {
      name: '模板',
      enname: tl['dict.report.template'],
      placeholder: '请选择模板',
      enplaceholder: tl['dict.select.template'],
      key: 'template',
      type: 'select',
      required: [{
        required: true
      }]
    }, {
      name: '最新发现时间',
      enname: tl['dict.last.discovery.time'],
      key: 'timeRange',
      type: 'timeRange',
      required: [{
        required: true
      }]
    }, {
      name: '品牌',
      enname: tl['dict.brand'],
      key: 'brandIds',
      type: 'checkbox',
      required: [{
        required: true
      }]
    }]
  },
  1: {
    name: '统计报表',
    list: [{
      name: '报表名称',
      enname: tl['dict.report.name'],
      key: 'name',
      type: 'input',
      enplaceholder: tl['dict.placeholder.report.name'],
      placeholder: '请输入报表',
      required: [{
        required: true
      }]
    }, {
      name: '描述',
      enname: tl['dict.description'],
      key: 'description',
      type: 'input',
      enplaceholder: tl['dict.placeholder.description'],
      placeholder: '请输入描述'
    }, {
      name: '数据类型',
      enname: tl['dict.report.configuration'],
      key: 'dataTypeList',
      type: 'checkbox',
      required: [{
        required: true
      }]
    }, {
      name: '统计指标',
      enname: tl['dict.report.configuration'],
      key: 'statistical_index',
      type: 'checkbox',
      required: [{
        required: true
      }]
    }, {
      name: '服务类型',
      enname: tl['dict.service.type'],
      key: 'service_type',
      type: 'checkbox',
      required: [{
        required: true
      }]
    }, {
      name: '状态',
      enname: tl['dict.ticket.status'],
      key: 'statistical_status',
      type: 'checkbox',
      required: [{
        required: true
      }]
    }, {
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'risk_level',
      type: 'checkbox'
    }, {
      name: '最新发现时间',
      enname: tl['dict.last.discovery.time'],
      key: 'timeRange',
      type: 'timeRange',
      required: [{
        required: true
      }]
    }, {
      name: '品牌',
      enname: tl['dict.brand'],
      key: 'brand_ids',
      type: 'checkbox',
      required: [{
        required: true
      }]
    }]
  },
  2: {
    name: '其他报告',
    list: [{
      name: '报告标题',
      enname: tl['dict.report.name'],
      key: 'reportName',
      type: 'input',
      enplaceholder: tl['dict.placeholder.report.name'],
      placeholder: '请输入报告标题',
      required: [{
        required: true,
        message: tl['dict.placeholder.report.name']
      }]
    }, {
      name: '对应客户',
      enname: tl['dict.report.customer'],
      key: 'companyId',
      type: 'select',
      enplaceholder: tl['placeholder.select.company'],
      placeholder: '请选择客户',
      required: [{
        required: true,
        message: tl['required.select.company']
      }]
    }, {
      name: '上传文件',
      enname: tl['dict.upload.file'],
      key: 'uploadFileInfoVoList',
      type: 'upload',
      uploadType: 'file',
      required: [{
        required: true,
        message: tl['dict.upload.file']
      }]
    }]
  },
  3: {
    name: '定制报告',
    list: [{
      name: '报告名称',
      enname: tl['dict.report.name'],
      key: 'name',
      type: 'input',
      enplaceholder: tl['dict.placeholder.report.name'],
      placeholder: '请输入报告标题',
      required: [{
        required: true,
        message: tl['dict.placeholder.report.name']
      }]
    }, {
      name: '描述',
      enname: tl['dict.description'],
      key: 'description',
      type: 'input',
      enplaceholder: tl['dict.placeholder.description'],
      placeholder: '请输入描述'
    }, {
      name: '选择客户 & 品牌',
      enname: tl['dict.brand'],
      key: 'brandIds',
      type: 'checkbox',
      required: [{
        required: true
      }]
    }, {
      name: '模板',
      enname: tl['dict.report.template'],
      placeholder: '请选择模板',
      enplaceholder: tl['dict.select.template'],
      key: 'template',
      required: [{
        required: true
      }]
    }, {
      name: '最新发现时间',
      enname: tl['dict.last.discovery.time'],
      key: 'type',
      required: [{
        required: true
      }]
    }, {
      name: '报告格式',
      enname: tl['dict.report.template'],
      placeholder: '请选择报告格式',
      enplaceholder: tl['dict.select.template'],
      key: 'fileTypes',
      required: [{
        required: true
      }]
    }, {
      name: '语言版本',
      enname: tl['dict.report.template'],
      placeholder: '请选择语言版本',
      enplaceholder: tl['dict.select.template'],
      key: 'languages',
      required: [{
        required: true
      }]
    }, {
      name: '定制项',
      enname: tl['dict.report.template'],
      placeholder: '请选择定制项',
      key: 'customizedItems'
    }, {
      name: '推送方式',
      enname: tl['dict.report.template'],
      placeholder: '请选择推送方式',
      enplaceholder: tl['dict.select.template'],
      key: 'noticeType'
    }]
  }
};
export const controlRiskRankDict = [{
  name: '客户风险数据排名Top5',
  enname: 'Tops 5 Customers',
  countType: 1,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '客户',
    enname: 'Customers'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '品牌风险数据排名Top5',
  enname: 'Tops 5 Brands',
  countType: 2,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '品牌',
    enname: 'Brands'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '侵权网站地域分布Top10',
  enname: 'Top 10 Regions for fraud websites',
  countType: 3,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '地理位置',
    enname: 'Regions'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '侵权App应用市场分布Top5',
  enname: 'Tops 5 APP Stores',
  countType: 4,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '应用市场名称',
    enname: 'App Stores'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '社交媒体欺诈平台分布Top5',
  enname: 'Tops 5 Social Media platforms',
  countType: 5,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '社媒平台名称',
    enname: 'Social Media'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '文档泄露平台分布Top5',
  enname: 'Tops 5 Platforms for Doc Leakage',
  countType: 6,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '泄露平台名称',
    enname: 'Platforms'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '代码泄露平台分布Top5',
  enname: 'Tops 5 Platforms for Code Leakage',
  countType: 7,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '泄露平台名称',
    enname: 'Platforms'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '版权盗版平台分布Top5',
  enname: 'Top 5 Pirated Platforms',
  countType: 8,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '版权盗版平台名称',
    enname: 'Platforms'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '行业排名Top5',
  enname: 'Top 5 industrys',
  countType: 9,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '行业',
    enname: 'Industrys'
  }, {
    name: '风险数量',
    enname: 'Quantity'
  }]
}, {
  name: '风险指数排名Top5',
  enname: 'Top 5  Customers with highest risk Value',
  countType: 10,
  columns: [{
    name: '排名',
    enname: 'Ranking'
  }, {
    name: '客户',
    enname: 'Customers'
  }, {
    name: '风险指数',
    enname: 'Quantity'
  }]
}];
export const companyStatusDict = [{
  name: '全部',
  enname: tl['dict.all'],
  type: ''
}, {
  name: '未关联',
  enname: tl['dict.individual.account'],
  type: 0
}, {
  name: '已关联',
  enname: tl['dict.entity.account'],
  type: 1
}];
export const reportStatusDict = [{
  name: '未发布',
  enname: tl['dict.report.unpublished'],
  value: 0
}, {
  name: '已发布',
  enname: tl['dict.report.published'],
  value: 1
}];
export const reasonDict = [{
  name: '处置类别有误',
  enname: 'Wrong processing type',
  key: '处置类别有误',
  enkey: 'Wrong processing type'
}, {
  name: '欺诈/侵权对象信息填写错误',
  enname: 'Wrong information of counterfeiting/infringing object',
  key: '欺诈/侵权对象信息填写错误',
  enkey: 'Wrong information of counterfeiting/infringing object'
}, {
  name: '品牌名称选择错误',
  enname: 'Wrong name of brand',
  key: '品牌名称选择错误',
  enkey: 'Wrong name of brand'
}, {
  name: '举证截图有误',
  enname: 'Wrong screenshot of proof',
  key: '举证截图有误',
  enkey: 'Wrong screenshot of proof'
}, {
  name: '举证材料不足，需补充上传新的证据材料',
  enname: 'Insufficient proof and new evidence required',
  key: '举证材料不足，需补充上传新的证据材料',
  enkey: 'Insufficient proof and new evidence required'
}, {
  name: '其它',
  enname: 'Others',
  key: -1,
  enkey: -1
}];
export const allStatusDict = [{
  name: '风险',
  enname: 'Risk',
  value: '2'
}, {
  name: '已忽略',
  enname: tl['dict.ignored'],
  value: '3'
}, {
  name: '已完成',
  enname: tl['dict.finished'],
  value: '7'
}, {
  name: '待审核',
  enname: 'Review Pending',
  value: '4'
}, {
  name: '重开待审核',
  enname: 'Re-opened',
  value: '11'
}, {
  name: '处置中',
  enname: 'Processing',
  value: '5'
}, {
  name: '审核未通过',
  enname: 'Review Failed',
  value: '9'
}, {
  name: '待内部审核',
  enname: 'Pending Internal Review',
  value: '13'
}, {
  name: '内部审核驳回',
  enname: 'Rejection of internal audit',
  value: '14'
}, {
  name: '已暂停',
  enname: 'Pending',
  value: '6'
}, {
  name: '已撤销',
  enname: 'Cancelled',
  value: '8'
}, {
  name: '已关闭',
  enname: 'Closed',
  value: '12'
}, {
  name: '已超时',
  enname: 'Timed Out',
  value: '-1'
}, {
  name: '客户自提',
  enname: 'Customer Creart',
  value: '-2'
}, {
  name: '已复活',
  enname: 'Re-opend',
  value: '-3'
}, {
  name: '每日新增',
  enname: 'Daily addition',
  value: '-5'
}];
//风险或工单字段
export const riskTicketTypeDict = {
  "0": {
    timeTypeDict: [{
      name: '发现时间',
      enname: tl['dict.discovery.time'],
      type: 'discovery'
    }, {
      name: '忽略时间',
      enname: tl['dict.ignore.time'],
      type: 'ignore'
    }],
    status: [{
      name: '风险',
      enname: 'Risk',
      value: '2'
    }, {
      name: '已忽略',
      enname: 'Ignored',
      value: '3'
    }, {
      name: '已忽略',
      enname: 'Ignored',
      value: 'Ignored',
      isHide: true
    }, {
      name: '待内部审核',
      enname: 'Pending Internal Review',
      value: 'Pending Approval',
      isHide: true
    }, {
      name: '审核未通过',
      enname: 'Review Failed',
      value: 'Not Approved',
      isHide: true
    }]
  },
  "1": {
    timeTypeDict: [{
      name: '提交时间',
      enname: tl['dict.submit.time'],
      type: 'submit'
    }, {
      name: '发现时间',
      enname: tl['dict.discovery.time'],
      type: 'discovery'
    }, {
      name: '完成时间',
      enname: tl['dict.finish.time'],
      type: 'finish'
    }],
    status: [{
      name: '全部',
      enname: 'All',
      value: ''
    }, {
      name: '已完成',
      enname: 'Finished',
      value: '7'
    }, {
      name: '待审核',
      enname: 'Review Pending',
      value: '4'
    }, {
      name: '重开待审核',
      enname: 'Re-opened',
      value: '11'
    }, {
      name: '处置中',
      enname: 'Processing',
      value: '5'
    }, {
      name: '审核未通过',
      enname: 'Review Failed',
      value: '9'
    }, {
      name: '待内部审核',
      enname: 'Pending Internal Review',
      value: '13,14'
    }, {
      name: '已暂停',
      enname: 'Pending',
      value: '6'
    }, {
      name: '已撤销',
      enname: 'Cancelled',
      value: '8'
    }, {
      name: '已超时',
      enname: 'Timed Out',
      value: '-1'
    }, {
      name: '已关闭',
      enname: 'Closed',
      value: '12'
    }, {
      name: '客户自提',
      enname: 'Customer Creart',
      value: '-2'
    }, {
      name: '已复活',
      enname: 'Re-opend',
      value: '-3'
    }, {
      name: '每日新增',
      enname: 'Daily addition',
      value: '-5'
    }]
  },
  'ticketExam': {
    status: [{
      name: '全部',
      enname: 'All',
      value: '4,11'
    }, {
      name: '待审核',
      enname: 'Review Pending',
      value: '4'
    }, {
      name: '重开待审核',
      enname: 'Re-opened',
      value: '11'
    }]
  },
  'innerExam': {
    status: [{
      name: '全部',
      enname: 'All',
      value: '13,14'
    }, {
      name: '待内部审核',
      enname: 'Pending Internal Review',
      value: '13'
    }, {
      name: '内部审核驳回',
      enname: 'Rejection of internal audit',
      value: '14'
    }]
  }
};
export const brandTypeDict = [{
  name: '已签约',
  enname: tl['dict.contracted.customers'],
  type: 1
}, {
  name: 'POC',
  enname: tl['dict.poc'],
  type: 0
}, {
  name: '新发现',
  enname: tl['dict.potential.customers'],
  type: -1
}];
export const assetNameDict = [{
  label: 'Firefox',
  enlabel: 'Firefox',
  value: 'firefox'
}, {
  label: 'Chrome',
  enlabel: 'Chrome',
  value: 'chrome'
}, {
  label: 'Safari',
  enlabel: 'Safari',
  value: 'safari'
}, {
  label: 'Opera',
  enlabel: 'Opera',
  value: 'opera'
}, {
  label: '其他',
  enlabel: 'Other',
  value: '-1'
}];
//风险等级
export const riskLevelDict = [{
  name: '高风险',
  enname: tl['dict.risk.level.high'],
  type: 3
}, {
  name: '中风险',
  enname: tl['dict.risk.level.middle'],
  type: 2
}, {
  name: '低风险',
  enname: tl['dict.risk.level.low'],
  type: 1
}];
//风险级别
export const timeSelect = [{
  name: '默认',
  enname: 'Default',
  value: ''
}, {
  name: '今日',
  enname: 'Today',
  value: 1
}, {
  name: '本周',
  enname: 'This week',
  value: 2
}, {
  name: '本月',
  enname: 'This month',
  value: 3
}];
export const isImgDict = [{
  name: '全部',
  enname: 'All',
  value: null
}, {
  name: '有',
  enname: 'Yes',
  value: 1
}, {
  name: '无',
  enname: 'No',
  value: 0
}];
//风险探测状态
export const riskDetectStatusDict = [{
  name: '待处理',
  enname: tl['dict.pending'],
  value: 0
}, {
  name: '已忽略',
  enname: tl['dict.ignored'],
  value: 1
}, {
  name: '已通过',
  enname: tl['dict.passed'],
  value: 2
}];
export const dealTimeType = [{
  name: '中位时长',
  enname: 'Median'
}, {
  name: '预警时长',
  enname: 'Alert'
}];
export const parentSenceDict = [{
  name: '全部类型',
  enname: 'All types',
  type: 0
}, {
  name: '侵权欺诈',
  enname: tl['phishing.brand'],
  type: 1
}, {
  name: '威胁误报',
  enname: tl['false.positive'],
  type: 3
}, {
  name: '数据泄露',
  enname: tl['data.leakage'],
  type: 4
}, {
  name: '版权盗版',
  enname: tl['copyright.piracy'],
  type: 16
}, {
  name: '暗网威胁',
  enname: tl['dark.web'],
  type: 15
}];
//场景
export const senceDict = {
  "1": {
    name: '侵权欺诈',
    enname: tl['phishing.brand'],
    list: [{
      name: '侵权欺诈 (网站)',
      enname: tl['phishing.brand.abuse.website'],
      type: 5,
      typeTagName: 'WEB',
      entypeTagName: 'WEB'
    }, {
      name: '侵权欺诈 (APP)',
      enname: tl['phishing.brand.abuse.app'],
      type: 6,
      typeTagName: 'APP',
      entypeTagName: 'APP'
    }, {
      name: '侵权欺诈 (社媒)',
      enname: tl['phishing.brand.abuse.social'],
      type: 7,
      typeTagName: '社媒',
      entypeTagName: 'SOCIAL'
    }, {
      name: '侵权欺诈 (内容)',
      enname: tl['phishing.brand.abuse.content'],
      type: 18,
      typeTagName: '内容',
      entypeTagName: 'CONTENT'
    }]
  },
  '3': {
    name: '威胁误报',
    enname: tl['false.positive'],
    list: [{
      name: '威胁误报 (网站)',
      enname: tl['false.positive.website'],
      type: 9,
      typeTagName: 'WEB',
      entypeTagName: 'WEB'
    }, {
      name: '威胁误报 (APP)',
      enname: tl['false.positive.app'],
      type: 8,
      typeTagName: 'APP',
      entypeTagName: 'APP'
    }]
  },
  '4': {
    name: '数据泄露',
    enname: tl['data.leakage'],
    list: [{
      name: '文档泄露',
      enname: tl['data.leakage.doc'],
      type: 11,
      typeTagName: '文档泄露',
      entypeTagName: 'DOC'
    }, {
      name: '代码泄露',
      enname: tl['data.leakage.code'],
      type: 12,
      typeTagName: '代码泄露',
      entypeTagName: 'CODE'
    }]
  },
  '16': {
    name: '版权盗版',
    enname: tl['copyright.piracy'],
    list: [{
      name: '版权盗版',
      enname: tl['copyright.piracy'],
      type: 14
    }]
  },
  '15': {
    name: '暗网威胁',
    enname: tl['dark.web'],
    hidden: true,
    list: [{
      name: '暗网威胁',
      enname: tl['dark.web'],
      type: 17
    }]
  },
  'all': {
    hidden: true,
    list: [{
      name: '侵权欺诈 (网站)',
      enname: tl['phishing.brand.abuse.website'],
      type: 5
    }, {
      name: '侵权欺诈 (APP)',
      enname: tl['phishing.brand.abuse.app'],
      type: 6
    }, {
      name: '侵权欺诈 (社媒)',
      enname: tl['phishing.brand.abuse.social'],
      type: 7
    }, {
      name: '侵权欺诈 (内容)',
      enname: tl['phishing.brand.abuse.content'],
      type: 18
    }, {
      name: '威胁误报 (APP)',
      enname: tl['false.positive.app'],
      type: 8
    }, {
      name: '威胁误报 (网站)',
      enname: tl['false.positive.website'],
      type: 9
    }, {
      name: '文档泄露',
      enname: tl['data.leakage.doc'],
      type: 11
    }, {
      name: '代码泄露',
      enname: tl['data.leakage.code'],
      type: 12
    }, {
      name: '版权盗版',
      enname: tl['copyright.piracy'],
      type: 14
    }, {
      name: '暗网威胁',
      enname: tl['dark.web'],
      type: 17
    }]
  }
};

//业务类型
export const serviceTypeDict = {
  "type5": {
    type: 5,
    name: '侵权欺诈(网站)',
    enname: tl['phishing.brand.abuse.website'],
    simpleName: '网站',
    ensimpleName: tl['dict.website'],
    uploadImgKey: 'evidence',
    riskDetectItemTitle: '侵权证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '网站',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '解析地址',
        enname: tl['dict.ip'],
        key: 'webIp'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '侵权项',
        enname: tl['dict.infringer.item'],
        key: 'infringement'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: 'asn',
        enname: tl['dict.asn'],
        key: 'asn'
      }, {
        name: '运营商',
        enname: tl['dict.isp'],
        key: 'carrier'
      }]
    },
    '1': {
      //工单
      listDict: [{
        name: '网站',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '解析地址',
        enname: tl['dict.ip'],
        key: 'webIp'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '侵权项',
        enname: tl['dict.infringer.item'],
        key: 'infringement'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'riskReviewTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: 'asn',
        enname: tl['dict.asn'],
        key: 'asn'
      }, {
        name: '运营商',
        enname: tl['dict.isp'],
        key: 'carrier'
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true
      }]
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true,
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '侵权证据',
      enname: tl['dict.evidence'],
      placeholder: '请填写侵权网址',
      enplaceholder: tl['dict.enter.infringing.website'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写侵权网址',
        message: tl['dict.enter.infringing.website']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '',
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence',
      required: [{
        required: true,
        dataMessage: '请上传举证截图',
        message: tl['dict.upload.limit']
      }]
    }, {
      name: '侵权项',
      enname: tl['dict.infringer.item'],
      key: 'infringement'
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.domain.registrar'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '举证截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence'
    }, {
      name: '侵权网址',
      enname: tl['dict.infringing.website'],
      placeholder: '请填写侵权网址',
      enplaceholder: tl['dict.enter.infringing.website'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        message: tl['dict.enter.infringing.website'],
        dataMessage: '请填写侵权网址'
      }, {
        validator: (value, callback) => {
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '侵权项',
      enname: tl['dict.infringer.item'],
      key: 'infringement'
    }]
  },
  "type7": {
    type: 7,
    name: '侵权欺诈(社交媒体)',
    enname: tl['phishing.brand.abuse.social'],
    simpleName: '社交媒体',
    ensimpleName: tl['dict.social.media'],
    uploadImgKey: 'evidence',
    riskDetectItemTitle: '侵权证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }, {
        name: '账号ID',
        enname: tl['dict.account.id'],
        key: 'user_id'
      }, {
        name: '欺诈内容',
        enname: tl['dict.social.content'],
        key: 'socialAccount'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '描述',
        enname: tl['dict.description'],
        noEllipsis: true,
        key: 'description',
        allHeight: true
      }]
    },
    '1': {
      //风险
      listDict: [{
        name: '社媒平台',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }, {
        name: '账号ID',
        enname: tl['dict.account.id'],
        key: 'user_id'
      }, {
        name: '欺诈内容',
        enname: tl['dict.social.content'],
        key: 'socialAccount'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'riskReviewTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }, {
        name: '描述',
        enname: tl['dict.description'],
        noEllipsis: true,
        key: 'description',
        allHeight: true
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true
      }]
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true,
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '侵权证据',
      enname: tl['dict.evidence'],
      mini: true,
      required: [{
        required: true
      }],
      children: [{
        name: '平台',
        enname: tl['dict.platform'],
        placeholder: '请选择平台',
        enplaceholder: tl['dict.select.platform'],
        mini: true,
        isInValue: true,
        key: 'platform',
        required: [{
          required: true,
          dataMessage: '请选择平台',
          message: tl['dict.select.platform']
        }]
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        mini: true,
        isInValue: true,
        placeholder: '请填写账号名称',
        enplaceholder: tl['dict.input.account'],
        key: 'username',
        required: [{
          required: true,
          dataMessage: '请填写侵权欺诈的账号名称',
          message: tl['dict.input.account']
        }],
        half: true
      }, {
        name: '欺诈账号Id',
        enname: tl['dict.account.id'],
        mini: true,
        isInValue: true,
        placeholder: '请填写账号ID',
        enplaceholder: tl['dict.input.account.id'],
        key: 'user_id',
        required: [{
          required: true,
          dataMessage: '账号ID为必填项',
          message: tl['dict.input.account.id']
        }],
        half: true
      }]
    }, {
      name: '',
      mini: true,
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence',
      required: [{
        required: true,
        dataMessage: '请上传举证截图',
        message: tl['dict.upload.limit']
      }]
    }, {
      name: '',
      isInValue: true,
      key: 'description',
      placeholder: '请描述本账号的侵权或恶意行为',
      enplaceholder: tl['dict.describe.infringement.behavior']
    }, {
      name: '账号网址',
      enname: tl['dict.infringing.website'],
      placeholder: '请填写侵权欺诈账号的网址',
      enplaceholder: tl['dict.enter.infringing.website'],
      key: 'webUrl',
      required: [{
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (value && !reg.test(value)) {
            console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }, {
      name: '备注',
      enname: tl['dict.remark'],
      placeholder: '请输入备注',
      enplaceholder: tl['dict.placeholder.remark'],
      key: 'remark'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '举证截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence'
    }, {
      name: 'URL',
      enname: 'URL',
      placeholder: '请填写URL',
      enplaceholder: tl['dict.enter.url'],
      mini: true,
      key: 'webUrl'
    }, {
      name: '平台名称',
      enname: tl['dict.platform'],
      placeholder: '请选择平台',
      enplaceholder: tl['dict.select.platform'],
      mini: true,
      key: 'platform',
      required: [{
        required: true
      }]
    }, {
      name: '欺诈账号 & ID',
      enname: tl['dict.account.account.id'],
      mini: true,
      required: [{
        required: true
      }],
      children: [{
        name: '',
        key: 'username',
        placeholder: '请填写账号名称',
        enplaceholder: tl['dict.input.account']
      }, {
        name: '',
        key: 'user_id',
        placeholder: '请填写账号ID',
        enplaceholder: tl['dict.input.account.id']
      }]
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请描述本账号的侵权或恶意行为',
      enplaceholder: tl['dict.describe.infringement.behavior'],
      mini: true,
      key: 'description'
    }]
  },
  "type6": {
    type: 6,
    name: '侵权欺诈(APP)',
    enname: tl['phishing.brand.abuse.app'],
    simpleName: 'APP',
    ensimpleName: tl['dict.app'],
    uploadImgKey: 'evidence',
    riskDetectItemTitle: '侵权证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: 'APP',
        enname: tl['dict.app'],
        key: 'webUrl'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: 'asn',
        enname: tl['dict.asn'],
        key: 'asn'
      }, {
        name: '运营商',
        enname: tl['dict.isp'],
        key: 'carrier'
      }]
    },
    '1': {
      //工单
      listDict: [{
        name: 'APP',
        enname: tl['dict.app'],
        key: 'webUrl'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'riskReviewTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: 'asn',
        enname: tl['dict.asn'],
        key: 'asn'
      }, {
        name: '运营商',
        enname: tl['dict.isp'],
        key: 'carrier'
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true
      }]
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '侵权证据',
      enname: tl['dict.evidence'],
      placeholder: '请填写侵权网址',
      enplaceholder: tl['dict.enter.infringing.website'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写侵权网址',
        message: tl['dict.enter.infringing.website']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '',
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence',
      required: [{
        required: true,
        dataMessage: '请上传举证截图',
        message: tl['dict.upload.limit']
      }]
    }, {
      name: '补充材料',
      enname: tl['dict.infringer.item'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }, {
      name: '备注',
      enname: tl['dict.supplementary.information'],
      placeholder: '请输入备注',
      enplaceholder: tl['dict.placeholder.remark'],
      key: 'remark'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '举证截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence'
    }, {
      name: 'APP链接',
      enname: tl['dict.app.link'],
      placeholder: '请填写app链接',
      enplaceholder: tl['dict.placeholder.app.link'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写app链接',
        message: tl['dict.placeholder.app.link']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }]
  },
  "type18": {
    type: 18,
    name: '侵权欺诈(内容)',
    enname: tl['phishing.brand.abuse.content'],
    simpleName: 'URL',
    ensimpleName: 'URL',
    uploadImgKey: 'evidence',
    riskDetectItemTitle: '侵权证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '内容发布时间',
        enname: tl['dict.publish.time'],
        key: 'wh_pubtime',
        type: 'time'
      }, {
        name: '风险发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }, {
        name: '账号ID',
        enname: tl['dict.account.id'],
        key: 'user_id'
      }, {
        name: '命中位置',
        enname: tl['dict.hitting.position'],
        key: 'hit_position'
      }, {
        name: '命中关键词',
        enname: tl['dict.hitting.keywords'],
        allHeight: true,
        key: 'hit_keywords'
      }, {
        name: '侵权标题',
        enname: tl['dict.infringement.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '内容详情',
        enname: tl['dict.content'],
        key: 'content',
        allHeight: true,
        noEllipsis: true
      }]
    },
    '1': {
      //风险
      listDict: [{
        name: '社媒平台',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        key: 'evidence'
      }, {
        name: '内容发布时间',
        enname: tl['dict.publish.time'],
        key: 'wh_pubtime',
        type: 'time'
      }, {
        name: '风险发现时间',
        enname: tl['dict.discovery.time'],
        key: 'riskReviewTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        key: 'username'
      }, {
        name: '账号ID',
        enname: tl['dict.account.id'],
        key: 'user_id'
      }, {
        name: '命中位置',
        enname: tl['dict.hitting.position'],
        key: 'hit_position'
      }, {
        name: '命中关键词',
        enname: tl['dict.hitting.keywords'],
        key: 'hit_keywords'
      }, {
        name: '侵权标题',
        enname: tl['dict.infringement.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '内容详情',
        enname: tl['dict.content'],
        key: 'content',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true
      }]
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true,
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '侵权证据',
      enname: tl['dict.evidence'],
      mini: true,
      required: [{
        required: true
      }],
      children: [{
        name: '平台',
        enname: tl['dict.platform'],
        placeholder: '请选择平台',
        enplaceholder: tl['dict.select.platform'],
        mini: true,
        isInValue: true,
        key: 'platform',
        required: [{
          required: true,
          dataMessage: '请选择平台',
          message: tl['dict.select.platform']
        }]
      }, {
        name: '账号网址',
        enname: tl['dict.infringing.website'],
        placeholder: '请填写侵权内容的网址',
        enplaceholder: tl['dict.enter.infringing.website'],
        key: 'webUrl',
        mini: true,
        required: [{
          required: true,
          dataMessage: '请填写账号网址',
          message: tl['dict.enter.infringing.website']
        }, {
          validator: (value, callback) => {
            // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
            let reg = /http(s)?:(\/?@%&=]*)?/;
            if (value && !reg.test(value)) {
              console.log('url格式不正确，请输入http(s)://');
              callback(tl['dict.url.limit']);
            }
          }
        }]
      }, {
        name: '欺诈账号',
        enname: tl['dict.account'],
        mini: true,
        isInValue: true,
        placeholder: '请填写账号名称',
        enplaceholder: tl['dict.input.account'],
        key: 'username',
        // required: [{required: true, message: '账号名称为必填项'}],
        half: true
      }, {
        name: '欺诈账号Id',
        enname: tl['dict.account.id'],
        mini: true,
        isInValue: true,
        placeholder: '请填写账号ID',
        enplaceholder: tl['dict.input.account.id'],
        key: 'user_id',
        // required: [{required: true, message: '账号ID为必填项'}],
        half: true
      }]
    }, {
      name: '',
      mini: true,
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence',
      required: [{
        required: true,
        message: tl['dict.upload.limit'],
        dataMessage: '请上传举证截图'
      }]
    },
    // {name: '',mini: true, isInValue: true, key: 'title', placeholder: '请填写侵权标题'},
    {
      name: '',
      mini: true,
      isInValue: true,
      key: 'content',
      placeholder: '请描述本账号的侵权或恶意行为...',
      enplaceholder: tl['dict.describe.infringement.behavior'],
      required: [{
        required: true,
        dataMessage: '请描述本账号的侵权或恶意行为...',
        message: tl['dict.describe.infringement.behavior']
      }]
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }, {
      name: '备注',
      enname: tl['dict.remark'],
      placeholder: '填写其他需要备注的信息...',
      enplaceholder: tl['dict.remark'],
      key: 'remark'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '举证截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence'
    }, {
      name: '侵权标题',
      enname: tl['dict.infringement.title'],
      placeholder: '请填写侵权标题',
      enplaceholder: tl['dict.placeholder.infringement.title'],
      mini: true,
      key: 'title'
    }, {
      name: '命中关键词',
      enname: tl['dict.hitting.position'],
      mini: true,
      key: 'hit_keywords',
      readOnly: true
    }, {
      name: '平台名称',
      enname: tl['dict.platform'],
      placeholder: '请选择平台',
      enplaceholder: tl['dict.select.platform'],
      mini: true,
      key: 'platform',
      required: [{
        required: true
      }]
    }, {
      name: '发布时间',
      enname: tl['dict.publish.time'],
      mini: true,
      key: 'wh_pubtime',
      readOnly: true
    }, {
      name: '侵权URL',
      enname: tl['dict.infringing.website'],
      mini: true,
      key: 'webUrl'
    }, {
      name: '欺诈账号 & ID',
      enname: tl['dict.account.account.id'],
      mini: true,
      required: [{
        required: true
      }],
      children: [{
        name: '',
        key: 'username',
        placeholder: '请填写欺诈账号',
        enplaceholder: tl['dict.input.account']
      }, {
        name: '',
        key: 'user_id',
        placeholder: '请填写ID',
        enplaceholder: tl['dict.input.account.id']
      }]
    }, {
      name: '内容详情',
      enname: tl['dict.content'],
      mini: true,
      key: 'content',
      colSpan: 24,
      readOnly: true
    }, {
      name: '命中位置',
      enname: tl['dict.hitting.position'],
      mini: true,
      key: 'hit_position',
      readOnly: true
    }]
  },
  "type9": {
    type: 9,
    name: '威胁误报(网站)',
    enname: tl['false.positive.website'],
    simpleName: '误报网站',
    ensimpleName: tl['dict.false.website'],
    uploadImgKey: 'evidence',
    riskDetectItemTitle: '泄露证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '网站',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '误报源',
        enname: tl['dict.source'],
        key: 'assetName'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '误报源',
        enname: tl['dict.source'],
        key: 'assetName'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }]
    },
    '1': {
      //工单
      listDict: [{
        name: '网站',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '误报源',
        enname: tl['dict.source'],
        key: 'assetName'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '误报源',
        enname: tl['dict.source'],
        key: 'assetName'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true
      }]
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '误报网站',
      enname: tl['dict.false.website'],
      placeholder: '请输入误报网站',
      enplaceholder: tl['dict.placeholder.false.positives.website'],
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请输入误报网站',
        message: tl['dict.placeholder.false.positives.website']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '误报源',
      enname: tl['dict.source'],
      key: 'assetName',
      required: [{
        required: true
      }],
      hidden: false
    }, {
      name: '其他误报源',
      enname: tl['dict.other.source'],
      key: 'falseReportUrl',
      hidden: true
    }, {
      name: '备注',
      enname: tl['dict.remark'],
      placeholder: '请输入备注',
      enplaceholder: tl['dict.placeholder.remark'],
      key: 'remark'
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '检测时间',
      enname: tl['dict.check.time'],
      key: 'discoveryTime',
      half: true,
      type: 'time'
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '举证截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence'
    }, {
      name: '网址',
      enname: tl['dict.website'],
      placeholder: '请填写网址',
      enplaceholder: tl['dict.placeholder.false.positives.website'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写网址',
        message: tl['dict.placeholder.false.positives.website']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '误报源',
      enname: tl['dict.source'],
      key: 'assetName',
      required: [{
        required: true
      }],
      hidden: false
    }, {
      name: '侵权项',
      enname: tl['dict.infringer.item'],
      key: 'infringement'
    }]
  },
  "type8": {
    type: 8,
    name: '威胁误报(APP)',
    enname: tl['false.positive.app'],
    simpleName: '误报APP',
    ensimpleName: tl['dict.false.app'],
    uploadImgKey: 'evidence',
    riskDetectItemTitle: '泄露证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '误报厂商',
        enname: tl['dict.positive.manufacturer'],
        key: 'falseReportCompany'
      }, {
        name: '应用名称',
        enname: tl['dict.app.name'],
        key: 'appName'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '误报厂商',
        enname: tl['dict.positive.manufacturer'],
        key: 'falseReportCompany'
      }, {
        name: '引擎名称',
        enname: tl['dict.engine.name'],
        key: 'engineName'
      }, {
        name: '引擎版本',
        enname: tl['dict.engine.version'],
        key: 'engineVersion'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '误报应用',
        enname: tl['dict.false.app'],
        key: 'appName'
      }, {
        name: 'SHA256',
        enname: 'SHA256',
        key: 'appSha256'
      }]
    },
    '1': {
      //工单
      listDict: [{
        name: '误报厂商',
        enname: tl['dict.positive.manufacturer'],
        key: 'falseReportCompany'
      }, {
        name: '应用名称',
        enname: tl['dict.app.name'],
        key: 'appName'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '误报厂商',
        enname: tl['dict.positive.manufacturer'],
        key: 'falseReportCompany'
      }, {
        name: '引擎名称',
        enname: tl['dict.engine.name'],
        key: 'engineName'
      }, {
        name: '引擎版本',
        enname: tl['dict.engine.version'],
        key: 'engineVersion'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '误报应用',
        enname: tl['dict.false.app'],
        key: 'appName'
      }, {
        name: 'SHA256',
        enname: 'SHA256',
        key: 'appSha256'
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true
      }],
      hidden: false
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      key: 'brandName',
      hidden: true
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      key: 'createUserName',
      hidden: true
    }, {
      name: '误报厂商',
      enname: tl['dict.positive.manufacturer'],
      placeholder: '请输入误报厂商',
      enplaceholder: tl['dict.please.enter.positive.manufacturer'],
      key: 'falseReportCompany',
      required: [{
        required: true
      }],
      hidden: false
    }, {
      name: '误报应用',
      enname: tl['dict.false.app'],
      placeholder: '上传文件后自动带入',
      enplaceholder: tl['dict.auto.after.upload'],
      mini: true,
      key: 'appSha256',
      readonly: true,
      required: [{
        required: true,
        dataMessage: '请上传误报应用',
        message: tl['dict.placeholder.upload.app']
      }]
    }, {
      name: '',
      placeholder: '请上传app格式文件',
      enplaceholder: tl['dict.upload.file.app.fomat'],
      limit: 1,
      key: 'appPath',
      requiredMsg: '误报应用',
      uploadType: 'file',
      required: [{
        required: true,
        dataMessage: '请上传app格式文件',
        message: tl['dict.upload.file.app.fomat']
      }]
    }, {
      name: '检测结果',
      enname: tl['dict.inspection.result'],
      placeholder: '请输入检测结果',
      enplaceholder: tl['dict.placeholder.enter.inspection.result'],
      key: 'detectResult',
      hidden: false
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }, {
      name: '引擎信息',
      enname: tl['dict.engine.information'],
      required: [{
        required: true
      }],
      children: [{
        name: '引擎名称',
        enname: tl['dict.engine.name'],
        key: 'engineName',
        placeholder: '请填写引擎名称',
        enplaceholder: tl['dict.placeholder.engine.name'],
        half: true,
        required: [{
          required: true,
          dataMessage: '请填写引擎名称',
          message: tl['dict.placeholder.engine.name']
        }]
      }, {
        name: '引擎版本',
        enname: tl['dict.engine.version'],
        key: 'engineVersion',
        placeholder: '请填写引擎版本',
        enplaceholder: tl['dict.placeholder.engine.version'],
        half: true,
        required: [{
          required: true,
          dataMessage: '请填写引擎版本',
          message: tl['dict.placeholder.engine.version']
        }]
      }]
    }, {
      name: '备注',
      enname: tl['dict.remark'],
      placeholder: '请输入备注',
      enplaceholder: tl['dict.placeholder.remark'],
      key: 'remark'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '举证截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'evidence'
    }, {
      name: 'APP链接',
      enname: tl['dict.app.link'],
      placeholder: '请填写APP链接',
      enplaceholder: tl['dict.placeholder.app.link'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写app链接',
        message: tl['dict.placeholder.app.link']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }]
  },
  "type11": {
    type: 11,
    name: '文档泄露',
    enname: tl['data.leakage.doc'],
    simpleName: '文档泄露',
    ensimpleName: tl['data.leakage.doc'],
    uploadImgKey: 'leakageContent',
    riskDetectItemTitle: '泄露证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '文档泄露',
        enname: tl['data.leakage.doc'],
        key: 'webUrl'
      }, {
        name: '泄露内容',
        enname: tl['dict.leaked.content'],
        key: 'leakage'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '泄露截图',
        enname: tl['dict.leaked.content'],
        key: 'leakageContent'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName',
        allHeight: true
      }, {
        name: '上传人',
        enname: tl['dict.uploader'],
        key: 'uploader'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '标题',
        enname: tl['dict.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '描述',
        enname: tl['dict.description'],
        key: 'description',
        allHeight: true,
        noEllipsis: true
      }]
    },
    '1': {
      //工单
      listDict: [{
        name: '文档泄露',
        enname: tl['data.leakage.doc'],
        key: 'webUrl'
      }, {
        name: '泄露内容',
        enname: tl['dict.leaked.content'],
        key: 'leakage'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '泄露截图',
        enname: tl['dict.leaked.content'],
        key: 'leakageContent'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName',
        allHeight: true
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '授权文件',
        enname: tl['dict.auth.file'],
        key: 'authorizationFile'
      }, {
        name: '上传人',
        enname: tl['dict.uploader'],
        key: 'uploader'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'riskReviewTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '标题',
        enname: tl['dict.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }, {
        name: '描述',
        enname: tl['dict.description'],
        key: 'description',
        allHeight: true,
        noEllipsis: true
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true,
        dataMessage: '请选择品牌',
        message: tl['select.brand']
      }],
      hidden: false
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '泄露内容',
      enname: tl['dict.leaked.content'],
      required: [{
        required: true
      }],
      children: [{
        name: '',
        mini: true,
        placeholder: '请选择平台',
        enplaceholder: tl['dict.select.platform'],
        isInValue: true,
        key: 'platform',
        required: [{
          required: true,
          dataMessage: '请选择平台',
          message: tl['dict.select.platform']
        }],
        hidden: false
      }, {
        name: '',
        mini: true,
        half: true,
        placeholder: '请填写泄露地址',
        enplaceholder: tl['dict.placeholder.leaked.url'],
        key: 'webUrl',
        required: [{
          required: true,
          dataMessage: '请填写泄露地址',
          message: tl['dict.placeholder.leaked.url']
        }, {
          validator: (value, callback) => {
            // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
            let reg = /http(s)?:(\/?@%&=]*)?/;
            if (!reg.test(value)) {
              // console.log('url格式不正确，请输入http(s)://');
              callback(tl['dict.url.limit']);
            }
          }
        }],
        hidden: false
      }, {
        name: '',
        mini: true,
        half: true,
        placeholder: '请填写泄露内容',
        enplaceholder: tl['dict.placeholder.leaked.content'],
        key: 'leakage',
        required: [{
          required: true,
          dataMessage: '请填写泄露内容',
          message: tl['dict.placeholder.leaked.content']
        }],
        hidden: false
      }]
    }, {
      name: '',
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '泄露截图',
      uploadType: 'img',
      key: 'leakageContent',
      required: [{
        required: true,
        dataMessage: '请上传泄露截图',
        message: tl['dict.upload.leaked.limit']
      }]
    }, {
      name: '标题',
      enname: tl['dict.title'],
      placeholder: '请填写泄露内容的标题，如某论坛的帖子标题：独行月球电影下载',
      enplaceholder: tl['dict.placeholder.title'],
      key: 'title',
      isInValue: true,
      hidden: false
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请描述具体侵权行为',
      enplaceholder: tl['dict.placeholder.description'],
      isInValue: true,
      key: 'description',
      hidden: false
    }, {
      name: '上传人',
      enname: tl['dict.uploader'],
      placeholder: '请填写泄露内容的上传人',
      enplaceholder: tl['dict.placeholder.uploader'],
      isInValue: true,
      key: 'uploader',
      hidden: false
    }, {
      name: '授权文件',
      enname: tl['dict.auth.file'],
      key: 'authorizationFile',
      limit: 1,
      uploadType: 'file'
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }, {
      name: '备注',
      enname: tl['dict.remark'],
      placeholder: '请输入备注',
      enplaceholder: tl['dict.placeholder.remark'],
      key: 'remark'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '泄露截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '泄露截图',
      uploadType: 'img',
      key: 'leakageContent'
    }, {
      name: '泄露源',
      enname: tl['dict.leaked.source'],
      placeholder: '请填写泄露地址',
      enplaceholder: tl['dict.placeholder.leaked.source'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写泄露地址',
        message: tl['dict.placeholder.leaked.source']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '平台名称',
      enname: tl['dict.platform'],
      placeholder: '请选择平台',
      enplaceholder: tl['dict.select.platform'],
      mini: true,
      key: 'platform',
      required: [{
        required: true,
        dataMessage: '请选择平台',
        message: tl['dict.select.platform']
      }]
    }, {
      name: '泄露内容',
      enname: tl['dict.leaked.content'],
      placeholder: '请填写泄露内容',
      enplaceholder: tl['dict.placeholder.leaked.content'],
      mini: true,
      key: 'leakage'
    }, {
      name: '标题',
      enname: tl['dict.title'],
      placeholder: '请填写标题',
      enplaceholder: tl['dict.placeholder.title'],
      mini: true,
      key: 'title'
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请填写描述',
      enplaceholder: tl['dict.placeholder.description'],
      mini: true,
      key: 'description'
    }, {
      name: '上传人',
      enname: tl['dict.uploader'],
      placeholder: '请填写上传人',
      enplaceholder: tl['dict.placeholder.uploader'],
      mini: true,
      key: 'uploader'
    }]
  },
  "type12": {
    type: 12,
    name: '代码泄露',
    enname: tl['data.leakage.code'],
    simpleName: '代码泄露',
    ensimpleName: tl['data.leakage.code'],
    uploadImgKey: 'leakageContent',
    riskDetectItemTitle: '泄露证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '代码泄露',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '泄露内容',
        enname: tl['dict.leaked.content'],
        key: 'leakage'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '泄露截图',
        enname: tl['dict.leaked.content'],
        key: 'leakageContent'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '上传人',
        enname: tl['dict.uploader'],
        key: 'uploader'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '标题',
        enname: tl['dict.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '描述',
        enname: tl['dict.description'],
        key: 'description',
        allHeight: true,
        noEllipsis: true
      }]
    },
    '1': {
      //工单
      listDict: [{
        name: '网站',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '泄露内容',
        enname: tl['dict.leaked.content'],
        key: 'leakage'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '泄露截图',
        enname: tl['dict.leaked.content'],
        key: 'leakageContent'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '授权文件',
        enname: tl['dict.auth.file'],
        key: 'authorizationFile'
      }, {
        name: '上传人',
        enname: tl['dict.uploader'],
        key: 'uploader'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'riskReviewTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '标题',
        enname: tl['dict.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }, {
        name: '描述',
        enname: tl['dict.description'],
        key: 'description',
        allHeight: true,
        noEllipsis: true
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true,
        dataMessage: '请选择品牌',
        message: tl['select.brand']
      }],
      hidden: false
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true,
        message: '请选择客户'
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '泄露内容',
      enname: tl['dict.leaked.content'],
      required: [{
        required: true
      }],
      children: [{
        name: '',
        mini: true,
        placeholder: '请选择平台',
        enplaceholder: tl['dict.select.platform'],
        isInValue: true,
        key: 'platform',
        required: [{
          required: true,
          dataMessage: '请选择平台',
          message: tl['dict.select.platform']
        }],
        hidden: false
      }, {
        name: '',
        mini: true,
        half: true,
        placeholder: '请填写泄露地址',
        enplaceholder: tl['dict.placeholder.leaked.url'],
        key: 'webUrl',
        required: [{
          required: true,
          dataMessage: '请填写泄露地址',
          message: tl['dict.placeholder.leaked.url']
        }, {
          validator: (value, callback) => {
            // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
            let reg = /http(s)?:(\/?@%&=]*)?/;
            if (!reg.test(value)) {
              // console.log('url格式不正确，请输入http(s)://');
              callback(tl['dict.url.limit']);
            }
          }
        }],
        hidden: false
      }, {
        name: '',
        mini: true,
        half: true,
        placeholder: '请填写泄露内容',
        enplaceholder: tl['dict.placeholder.leaked.content'],
        key: 'leakage',
        required: [{
          required: true,
          dataMessage: '请填写泄露内容',
          message: tl['dict.placeholder.leaked.content']
        }],
        hidden: false
      }]
    }, {
      name: '',
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '泄露截图',
      uploadType: 'img',
      key: 'leakageContent',
      required: [{
        required: true,
        dataMessage: '请上传泄露截图',
        message: tl['dict.upload.leaked.limit']
      }]
    }, {
      name: '标题',
      enname: tl['dict.title'],
      placeholder: '请填写泄露内容的标题，如某论坛的帖子标题：独行月球电影下载',
      enplaceholder: tl['dict.placeholder.title'],
      key: 'title',
      isInValue: true,
      hidden: false
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请描述具体侵权行为',
      enplaceholder: tl['dict.placeholder.description'],
      isInValue: true,
      key: 'description',
      hidden: false
    }, {
      name: '上传人',
      enname: tl['dict.uploader'],
      placeholder: '请填写泄露内容的上传人',
      enplaceholder: tl['dict.placeholder.uploader'],
      isInValue: true,
      key: 'uploader',
      hidden: false
    }, {
      name: '授权文件',
      enname: tl['dict.auth.file'],
      key: 'authorizationFile',
      limit: 1,
      uploadType: 'file'
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }, {
      name: '备注',
      enname: tl['dict.remark'],
      placeholder: '请输入备注',
      enplaceholder: tl['dict.placeholder.remark'],
      key: 'remark'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '泄露截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '泄露截图',
      uploadType: 'img',
      key: 'leakageContent'
    }, {
      name: '泄露源',
      enname: tl['dict.leaked.source'],
      placeholder: '请填写泄露地址',
      enplaceholder: tl['dict.placeholder.leaked.source'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写泄露地址',
        message: tl['dict.placeholder.leaked.source']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '平台名称',
      enname: tl['dict.platform'],
      placeholder: '请选择平台',
      enplaceholder: tl['dict.select.platform'],
      mini: true,
      key: 'platform',
      required: [{
        required: true,
        dataMessage: '请选择平台',
        message: tl['dict.select.platform']
      }]
    }, {
      name: '泄露内容',
      enname: tl['dict.leaked.content'],
      placeholder: '请填写泄露内容',
      enplaceholder: tl['dict.placeholder.leaked.content'],
      mini: true,
      key: 'leakage'
    }, {
      name: '标题',
      enname: tl['dict.title'],
      placeholder: '请填写标题',
      enplaceholder: tl['dict.placeholder.title'],
      mini: true,
      key: 'title'
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请填写描述',
      enplaceholder: tl['dict.placeholder.description'],
      mini: true,
      key: 'description'
    }, {
      name: '上传人',
      enname: tl['dict.uploader'],
      placeholder: '请填写上传人',
      enplaceholder: tl['dict.placeholder.uploader'],
      mini: true,
      key: 'uploader'
    }]
  },
  "type14": {
    type: 14,
    name: '版权盗版',
    enname: tl['copyright.piracy'],
    simpleName: '盗版源',
    ensimpleName: tl['dict.website'],
    uploadImgKey: 'leakageContent',
    riskDetectItemTitle: '盗版证据',
    enriskDetectItemTitle: tl['dict.evidence'],
    '0': {
      //风险
      listDict: [{
        name: '盗版源',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '泄露内容',
        enname: tl['dict.leaked.content'],
        key: 'leakage'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '泄露截图',
        enname: tl['dict.leaked.content'],
        key: 'leakageContent'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '上传人',
        enname: tl['dict.uploader'],
        key: 'uploader'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '标题',
        enname: tl['dict.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '描述',
        enname: tl['dict.description'],
        key: 'description',
        allHeight: true,
        noEllipsis: true
      }]
    },
    '1': {
      //工单
      listDict: [{
        name: '盗版源',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '泄露内容',
        enname: tl['dict.leaked.content'],
        key: 'leakage'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '泄露截图',
        enname: tl['dict.leaked.content'],
        key: 'leakageContent'
      }, {
        name: '平台名称',
        enname: tl['dict.platform'],
        key: 'dbpTicketPlatformName'
      }, {
        name: '补充材料',
        enname: tl['dict.supplementary.information'],
        key: 'supplementalMaterial'
      }, {
        name: '授权文件',
        enname: tl['dict.auth.file'],
        key: 'authorizationFile'
      }, {
        name: '上传人',
        enname: tl['dict.uploader'],
        key: 'uploader'
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'riskReviewTime',
        type: 'time'
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '解析地址',
        enname: tl['dict.ip.address'],
        key: 'webIp'
      }, {
        name: '地理位置',
        enname: tl['dict.location'],
        key: lang == 'en' ? 'locationEn' : 'location'
      }, {
        name: '标题',
        enname: tl['dict.title'],
        key: 'title',
        allHeight: true,
        noEllipsis: true
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        key: 'remark'
      }, {
        name: '描述',
        enname: tl['dict.description'],
        key: 'description',
        allHeight: true,
        noEllipsis: true
      }]
    },
    editArr: [{
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandId',
      required: [{
        required: true
      }],
      hidden: false
    }, {
      name: '品牌名称',
      enname: tl['dict.brandName'],
      placeholder: '选择品牌',
      enplaceholder: tl['select.brand'],
      key: 'brandName',
      hidden: true
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserId',
      required: [{
        required: true
      }]
    }, {
      name: '指定客户',
      enname: tl['dict.special.customers'],
      placeholder: '选择客户',
      key: 'createUserName',
      hidden: true
    }, {
      name: '盗版证据',
      enname: tl['dict.piracy.evidence'],
      mini: true,
      required: [{
        required: true
      }],
      children: [{
        name: '平台',
        enname: tl['dict.platform'],
        placeholder: '请选择平台',
        enplaceholder: tl['dict.select.platform'],
        mini: true,
        isInValue: true,
        key: 'platform',
        required: [{
          required: true,
          dataMessage: '请选择平台',
          message: tl['dict.select.platform']
        }]
      }]
    }, {
      name: '',
      placeholder: '请填写盗版网址',
      enplaceholder: tl['dict.placeholder.website'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写盗版网址',
        message: tl['dict.placeholder.website']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }],
      hidden: false
    }, {
      name: '',
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '泄露截图',
      uploadType: 'img',
      key: 'leakageContent',
      required: [{
        required: true,
        message: tl['dict.upload.limit'],
        dataMessage: '请上传举证截图'
      }]
    }, {
      name: '标题',
      enname: tl['dict.title'],
      placeholder: '填写盗版内容的标题，如某论坛的帖子标题：独行月球电影下载',
      enplaceholder: tl['dict.placeholder.title'],
      key: 'title',
      isInValue: true,
      hidden: false
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请描述具体侵权行为',
      enplaceholder: tl['dict.placeholder.description'],
      isInValue: true,
      key: 'description',
      hidden: false
    }, {
      name: '上传人',
      enname: tl['dict.uploader'],
      placeholder: '请填写盗版内容的上传人',
      enplaceholder: tl['dict.placeholder.uploader'],
      isInValue: true,
      key: 'uploader',
      hidden: false
    }, {
      name: '授权文件',
      enname: tl['dict.auth.file'],
      key: 'authorizationFile',
      uploadType: 'file'
    }, {
      name: '补充材料',
      enname: tl['dict.supplementary.information'],
      key: 'supplementalMaterial',
      uploadType: 'file'
    }],
    riskDetectDetailArrDict: [{
      name: '地理位置',
      enname: tl['dict.location'],
      key: lang == 'en' ? 'locationEn' : 'location',
      half: true
    }, {
      name: 'ASN',
      enname: tl['dict.asn'],
      key: 'asn',
      half: true
    }, {
      name: '解析地址',
      enname: tl['dict.ip.address'],
      key: 'webIp',
      half: true
    }, {
      name: '运营商',
      enname: tl['dict.isp'],
      key: 'carrier',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '原因',
      enname: tl['dict.reason'],
      key: 'reason'
    }, {
      name: '忽略原因',
      enname: tl['dict.ignore.reason'],
      key: 'detectIgnoreReason'
    }, {
      name: '原始数据',
      enname: tl['dict.primary.data'],
      key: 'raw'
    }],
    riskDetectEditArr: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '泄露截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '泄露截图',
      uploadType: 'img',
      key: 'leakageContent'
    }, {
      name: '盗版源',
      enname: tl['dict.website'],
      placeholder: '请填写盗版网址',
      enplaceholder: tl['dict.placeholder.website'],
      mini: true,
      key: 'webUrl',
      required: [{
        required: true,
        dataMessage: '请填写盗版网址',
        message: tl['dict.placeholder.website']
      }, {
        validator: (value, callback) => {
          // let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
          let reg = /http(s)?:(\/?@%&=]*)?/;
          if (!reg.test(value)) {
            // console.log('url格式不正确，请输入http(s)://');
            callback(tl['dict.url.limit']);
          }
        }
      }]
    }, {
      name: '平台名称',
      enname: tl['dict.platform'],
      placeholder: '请选择平台',
      enplaceholder: tl['dict.select.platform'],
      mini: true,
      key: 'platform',
      required: [{
        required: true,
        dataMessage: '请选择平台',
        message: tl['dict.select.platform']
      }]
    }, {
      name: '泄露内容',
      enname: tl['dict.leaked.content'],
      placeholder: '请填写泄露内容',
      enplaceholder: tl['dict.placeholder.leaked.content'],
      mini: true,
      key: 'leakage'
    }, {
      name: '标题',
      enname: tl['dict.title'],
      placeholder: '请填写标题',
      enplaceholder: tl['dict.placeholder.title'],
      mini: true,
      key: 'title'
    }, {
      name: '描述',
      enname: tl['dict.description'],
      placeholder: '请填写描述',
      enplaceholder: tl['dict.placeholder.description'],
      mini: true,
      key: 'description'
    }, {
      name: '上传人',
      enname: tl['dict.uploader'],
      placeholder: '请填写上传人',
      enplaceholder: tl['dict.placeholder.uploader'],
      mini: true,
      key: 'uploader'
    }]
  },
  "type17": {
    type: 17,
    name: '暗网审核',
    enname: tl['dark.web'],
    simpleName: '网页',
    ensimpleName: tl['dict.website'],
    uploadImgKey: 'leakageContent',
    '0': {
      //风险
      listDict: [{
        name: '网页',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '暗网平台名称',
        enname: tl['dict.platform'],
        key: 'website_name'
      }],
      detailDict: [{
        name: '指定客户',
        enname: tl['dict.special.customers'],
        key: 'createUserName'
      }, {
        name: '举证截图',
        enname: tl['dict.screenshots'],
        allHeight: true,
        key: 'leakage'
      }, {
        name: '网站链接',
        enname: tl['dict.website'],
        key: 'webUrl'
      }, {
        name: '替换原因',
        enname: tl['dict.reason'],
        key: 'reason',
        isDesensitized: true,
        fromDict: true
      }, {
        name: '品牌关联性',
        enname: tl['dict.correlation'],
        key: 'brandCorrelation',
        isDesensitized: true,
        fromDict: true
      }, {
        name: '泄露渠道',
        enname: tl['dict.dark.leakage.channel'],
        key: 'leakagePathType',
        isDesensitized: true,
        fromDict: true
      }, {
        name: '数据量级',
        enname: tl['dict.quantity.unit'],
        key: 'leakageDataLevel',
        isDesensitized: true
      }, {
        name: '风险信息类型',
        enname: tl['dict.risk.type'],
        key: 'riskInfoType',
        isDesensitized: true,
        fromDict: true
      }, {
        name: '是否存在数据样本',
        enname: tl['dict.dark.samples'],
        key: 'dataTradingExists',
        isDesensitized: true,
        fromDict: true
      }, {
        name: '暗网平台名称',
        enname: tl['dict.dark.platform'],
        key: 'website_name',
        half: true
      }, {
        name: 'TG群组名称',
        enname: tl['dict.dark.group'],
        key: 'telegram_group',
        half: true
      }, {
        name: '发现时间',
        enname: tl['dict.discovery.time'],
        key: 'discoveryTime',
        type: 'time',
        half: true
      }, {
        name: '最新发现时间',
        enname: tl['dict.last.discovery.time'],
        key: 'last_review_time',
        type: 'time'
      }, {
        name: '上传者账号',
        enname: tl['dict.dark.account'],
        key: 'username',
        half: true
      }, {
        name: '上传时间',
        enname: tl['dict.upload.time'],
        key: 'upload_time',
        half: true,
        type: 'time'
      }, {
        name: '备注',
        enname: tl['dict.remark'],
        placeholder: '请输入备注',
        enplaceholder: tl['dict.placeholder.remark'],
        key: 'remark'
      }, {
        name: '内容详情',
        enname: tl['dict.description'],
        key: 'description',
        allHeight: true,
        noEllipsis: true
      }]
    },
    riskDetectDetailArrDict: [{
      name: '风险等级',
      enname: tl['dict.risk.level'],
      key: 'riskLevel',
      required: [{
        required: true
      }]
    }, {
      name: '暗网平台名称',
      enname: tl['dict.dark.platform'],
      key: 'website_name',
      half: true
    }, {
      name: '暗网URL',
      enname: tl['dict.website'],
      key: 'webUrl',
      half: true
    }, {
      name: '域名注册商',
      enname: tl['dict.domain.registrar'],
      key: 'webDomainRegistrar'
    }, {
      name: '上传者账号',
      enname: tl['dict.dark.account'],
      key: 'username',
      half: true
    }, {
      name: '上传时间',
      enname: tl['dict.upload.time'],
      key: 'upload_time',
      half: true,
      type: 'time'
    }, {
      name: 'TG群组名称',
      enname: tl['dict.dark.group'],
      key: 'telegram_group',
      half: true
    }, {
      name: '描述',
      enname: tl['dict.description'],
      key: 'leakage',
      noEllipsis: true
    }, {
      name: '举证截图',
      enname: tl['dict.screenshots'],
      placeholder: '最多可上传8张图片，每张大小不超过5MB',
      enplaceholder: tl['dict.upload.length.limit'],
      requiredMsg: '举证截图',
      uploadType: 'img',
      key: 'leakageContent'
    }],
    riskDetectEditArr: [{
      name: '替换原因',
      enname: tl['dict.reason'],
      placeholder: '请选择替换原因',
      enplaceholder: tl['dict.select.reason'],
      mini: true,
      type: 'radio',
      key: 'reason',
      required: [{
        required: true,
        dataMessage: '请选择替换原因',
        message: tl['dict.select.reason']
      }]
    }, {
      name: '品牌关联性',
      enname: tl['dict.correlation'],
      placeholder: '请选择品牌关联性',
      enplaceholder: tl['dict.select.correlation'],
      mini: true,
      type: 'radio',
      key: 'brandCorrelation',
      required: [{
        required: true,
        dataMessage: '请选择品牌关联性',
        message: tl['dict.select.correlation']
      }]
    }, {
      name: '泄露渠道',
      enname: tl['dict.dark.leakage.channel'],
      placeholder: '请选择泄露渠道',
      enplaceholder: tl['dict.select.dark.leakage.channel'],
      mini: true,
      type: 'radio',
      key: 'leakagePathType',
      required: [{
        required: true,
        dataMessage: '请选择泄露渠道',
        message: tl['dict.select.dark.leakage.channel']
      }]
    }, {
      name: '风险信息类型',
      enname: tl['dict.dark.risk.type'],
      placeholder: '请选择风险信息类型',
      enplaceholder: tl['dict.select.dark.risk.type'],
      mini: true,
      type: 'select',
      key: 'riskInfoType',
      required: [{
        required: true,
        dataMessage: '请选择风险信息类型',
        message: tl['dict.select.dark.risk.type']
      }]
    }, {
      name: '数据量级',
      enname: tl['dict.quantity.unit'],
      placeholder: '请填写数据量级',
      enplaceholder: tl['dict.placeholder.quantity.unit'],
      mini: true,
      key: 'leakageDataLevel',
      required: [{
        required: true,
        dataMessage: '请填写数据量级',
        message: tl['dict.placeholder.quantity.unit']
      }]
    }, {
      name: '存在数据样本',
      enname: tl['dict.dark.samples'],
      placeholder: '请选择数据样本',
      enplaceholder: tl['dict.select.dark.samples'],
      mini: true,
      type: 'radio',
      key: 'dataTradingExists',
      required: [{
        required: true,
        dataMessage: '请选择数据样本',
        message: tl['dict.select.dark.samples']
      }]
    }]
  }
};
export const darkFormDict = {
  'reason': [
  //替换原因
  {
    name: '涉及违法信息',
    enname: 'Illegal information involved',
    key: 1
  }, {
    name: '暴露个人信息',
    enname: 'Personal information exposed',
    key: 2
  }, {
    name: '含有敏感话题、画面',
    enname: 'Sensitive topics and images contained',
    key: 3
  }],
  'brandCorrelation': [
  //品牌关联性
  {
    name: '直接关联',
    enname: 'Direct correlation',
    key: 1
  }, {
    name: '间接关联',
    enname: 'Indirect correlation',
    key: 2
  }],
  'leakagePathType': [
  //泄露渠道
  {
    name: '第三方',
    enname: 'Third parties',
    key: 1
  }, {
    name: '直接',
    enname: 'Local channel',
    key: 2
  }, {
    name: '未知',
    enname: 'Unknown',
    key: 3
  }],
  'riskInfoType': [
  //风险信息类型
  {
    name: '隐私',
    enname: 'Privacy',
    key: 1
  }, {
    name: '个人信息',
    enname: 'Personal information',
    key: 2
  }, {
    name: '文档',
    enname: 'Files',
    key: 3
  }, {
    name: '代码',
    enname: 'Code',
    key: 4
  }, {
    name: '其他',
    enname: 'Other',
    key: 5
  }],
  'dataTradingExists': [
  //存在数据样本
  {
    name: '存在',
    enname: 'Existed',
    key: 1
  }, {
    name: '不存在',
    enname: 'Not existed',
    key: 0
  }]
};