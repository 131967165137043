import "@arco-design/web-react/es/Modal/style";
import _Modal from "@arco-design/web-react/es/Modal";
import "@arco-design/web-react/es/Notification/style";
import _Notification from "@arco-design/web-react/es/Notification";
import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
import qs from 'qs';
import cache from '@/plugins/cache';
import errorCode from '@/utils/errorCode';
import eventBus from '@/utils/eventBus';
import util from '@/utils/util';
import apiConfig from "@/api/config";
import { logout } from '@/api/page/login';

// 是否显示重新登录
export let isRelogin = {
  show: false
};

/**
 * 如果有多个请求都是 403 就需要这个开关 来控制message的展示个数
 * 展示一个之后  关闭阀门
 */
let messageFlag = false;
/**
 * props是app.js页面传入的 this.props
 * 用于路由跳转  当403的时候 进行路由跳转
 */
let props = "";
/**
 * 当使用这个js的时候 会监听这个自定义事件
 * 改变props的值
 */
eventBus.$on(propsObj => {
  props = propsObj;
}, 'axiosInterceptorsFun');
let lang = localStorage.getItem('arco-lang');
lang = lang ? lang.split('-')[0] == 'en' ? 'en' : '' : '';
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.BASE_API,
  baseURL: apiConfig.baseURL,
  // 超时
  timeout: 30000
});
// request拦截器
service.interceptors.request.use(config => {
  eventBus.$emit('ajaxLoading', config.isLoading);
  // 是否需要防止数据重复提交
  if (config.requestType == 'file') {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }
  config.headers['X-Requested-With'] = ' XMLHttpRequest';
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
  if (!config.noQuery && typeof config.data === 'object') {
    if (config.requestType == 'file') {
      let formData = new FormData();
      formData.append('query', JSON.stringify(config.data));
      formData.append('upload', config.upload);
      config.data = formData;
    } else {
      config.data = qs.stringify({
        query: JSON.stringify(config.data)
      });
    }
  }
  if (config.noQuery) {
    config.data = qs.stringify(config.data);
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: config.data,
      time: new Date().getTime()
    };
    const sessionObj = cache.session.getJSON('sessionObj');
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj);
    } else {
      const s_url = sessionObj.url; // 请求地址
      const s_data = sessionObj.data; // 请求数据
      const s_time = sessionObj.time; // 请求时间
      const interval = 100; // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = lang == 'en' ? 'The data is being processed, please try again later' : '数据正在处理，请勿重复提交';
        console.log(requestObj.url);
        return Promise.reject(new Error(message));
      } else {
        cache.session.setJSON('sessionObj', requestObj);
      }
    }
  }
  return config;
}, error => {
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(res => {
  eventBus.$emit('ajaxLoading', false);
  // 未设置状态码则默认成功状态
  let isNoError = res.config.noError;
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default'];
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data;
  }
  if (code === 401) {
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
  } else if (code === 500) {
    _Message.error(msg);
    return Promise.reject(new Error(msg));
  } else if (code === 601) {
    _Message.warning(msg);
    return Promise.reject('error');
  } else if (code !== 200) {
    _Notification.error({
      title: msg
    });
    return Promise.reject('error');
  } else {
    if (isNoError) {
      return res.data;
    } else {
      if (res.data.result.result === 0) {
        return res.data;
      } else if (res.data.result.result == -4) {
        if (!isRelogin.show) {
          isRelogin.show = true;
          _Modal.info({
            icon: null,
            title: null,
            content: lang == 'en' ? 'Your login session has expired. You can stay on this page or log in again.' : '登录状态已过期，您可以继续留在该页面，或者重新登录',
            // content: tl['dict.login.expired.again'],
            // okText: '重新登录',
            okText: lang == 'en' ? 'Re-Login' : '重新登录',
            maskClosable: false,
            escToExit: false,
            onOk: async () => {
              isRelogin.show = false;
              // const url = apiConfig.logoutHttp + `?target=${window.location.href}`;
              await logout();
              window.location.href = '/login';
            }
          });
        }
        return Promise.reject('error');
      } else {
        util.showMsg(res.data.result);
        return Promise.reject('error');
      }
    }
  }
}, error => {
  let {
    message
  } = error;
  if (lang != 'en') {
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
  }
  _Message.error(message);
  return Promise.reject(error);
});
export default service;