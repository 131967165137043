import defaultSettings from '../settings.json';
const initialState = {
  settings: defaultSettings,
  platformList: [],
  serviceTypeAuth: [],
  companyData: {},
  userInfo: {
    permissions: {},
    rolePermission: {}
  }
};
export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-loading':
      {
        const {
          userLoading
        } = action.payload;
        return {
          ...state,
          userLoading
        };
      }
    case 'update-settings':
      {
        const {
          settings
        } = action.payload;
        return {
          ...state,
          settings
        };
      }
    case 'update-serviceTypeAuth':
      {
        const {
          serviceTypeAuth
        } = action.payload;
        return {
          ...state,
          serviceTypeAuth
        };
      }
    case 'update-platformList':
      {
        const {
          platformList
        } = action.payload;
        return {
          ...state,
          platformList
        };
      }
    case 'update-userInfo':
      {
        const {
          userInfo = initialState.userInfo
        } = action.payload;
        return {
          ...state,
          userInfo
        };
      }
    case 'update-searchKey':
      {
        const {
          searchKey
        } = action.payload;
        return {
          ...state,
          searchKey
        };
      }
    case 'update-companyData':
      {
        const {
          data,
          display,
          innerDisplay
        } = action.payload;
        return {
          ...state,
          companyData: {
            data,
            display,
            innerDisplay
          }
        };
      }
    default:
      return state;
  }
}