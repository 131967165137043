import globalI18n from '@/locale';
const i18n = {
  'en-US': {
    ...globalI18n['en-US'],
    'parent.brand': 'Parent Brand',
    'brand.logo': 'Logo',
    'asset.attributes': 'Asset Attributes',
    'select.asset.attributes': 'Select Asset Attributes',
    'domain.status': 'Status',
    'select.domain.status': 'Select Status',
    'admin.unconfirmed': 'Unconfirmed',
    'admin.confirm.owned': 'Owned',
    'admin.owned': 'Owned',
    'admin.not.owned': 'Not Owned',
    'admin.domain.ok': 'OK',
    'admin.domain.expired': 'Domain Expired',
    'admin.certificate.expired': 'Certificate Expired',
    'admin.domain.expired.time': 'Domain Expiration Time',
    'admin.certificate.expired.time': 'Certificate Expiration Time',
    'admin.ICP.licensing': 'ICP Licensing',
    'admin.app.download.url': 'Download URL',
    'admin.app.hash': 'Hash',
    'admin.miniProgram': 'Mini Program',
    'admin.miniProgram.logo': 'Logo',
    'admin.miniProgram.name': 'Name',
    'admin.registered.trademark': 'Registered Trademark',
    'admin.edit.asset': 'Edit',
    'dict.customer': 'Customer',
    'dict.account': 'Account',
    'dict.account.url': 'Account Url',
    'dict.registration.number': 'Registration Number',
    'dict.abbreviation': 'Abbreviation',
    'dict.version.number': 'Version Number',
    'dict.copyright.registration.number': 'Number'
  },
  'zh-CN': {
    ...globalI18n['zh-CN'],
    'parent.brand': '父品牌',
    'brand.logo': '品牌Logo',
    'asset.attributes': '资产属性',
    'select.asset.attributes': '请选择资产属性',
    'domain.status': '域名状态',
    'select.domain.status': '请选择域名状态',
    'admin.unconfirmed': '待确认资产',
    'admin.confirm.owned': '确认自有资产',
    'admin.owned': '自有资产',
    'admin.not.owned': '非自有资产',
    'admin.domain.ok': '正常',
    'admin.domain.expired': '域名过期',
    'admin.certificate.expired': '证书过期',
    'admin.domain.expired.time': '域名有效期',
    'admin.certificate.expired.time': '证书有效期',
    'admin.ICP.licensing': 'ICP备案证书',
    'admin.app.download.url': 'APP官网下载链接',
    'admin.app.hash': 'APP程序HASH值',
    'admin.miniProgram': '小程序',
    'admin.miniProgram.logo': '小程序logo',
    'admin.miniProgram.name': '小程序名称',
    'admin.registered.trademark': '注册商标',
    'admin.edit.asset': '编辑资产',
    'dict.customer': '所属客户',
    'dict.account': '账号名称',
    'dict.account.url': '账号链接',
    'dict.registration.number': '注册号',
    'dict.abbreviation': '简称',
    'dict.version.number': '版本号',
    'dict.copyright.registration.number': '登记号'
  }
};
export default i18n;